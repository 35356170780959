<template>
    <div>
        <div class="d-flex align-items-baseline color-picker-label">
            <label class="font-weight-bold mb-1 f-11 text-muted text-nowrap" :class="{ 'text-disabled': disabled }">
                {{ label }}
            </label>
            <PremiumMarker feature="color" class="ml-1 text-height-1">
                <p>Changing the color is a premium feature.</p>
            </PremiumMarker>
            <span
                v-if="color !== defaultColor"
                v-b-tooltip.hover
                title="Reset"
                class="ml-1 pointer text-height-1"
                @click="resetColor"
            >
                <IconArrowClockwise class="text-danger reset-btn" />
            </span>
        </div>
        <b-form-input
            v-model="color"
            type="color"
            size="sm"
            class="min-width"
            :disabled="disabled"
            @change="$emit('change')"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { default_color } from '../store/helpers/documentHelpers'
import IconArrowClockwise from '../stories/components/icons/IconArrowClockwise.vue'
import EditPayWall from '../mixins/EditPayWall'

export default defineComponent({
    name: 'ColorPicker',
    components: {
        IconArrowClockwise,
        PremiumMarker: () => import('../widgets/premium-marker.vue'),
    },
    mixins: [EditPayWall],
    model: {
        prop: 'modelValue',
        event: 'input',
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: 'Color',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        defaultColor: {
            type: String,
            default: default_color,
        },
    },

    computed: {
        color: {
            get() {
                return this.modelValue
            },
            set(value: string) {
                this.$emit('input', value)
                this.$emit('change', value)
            },
        },
    },

    methods: {
        resetColor() {
            this.color = this.defaultColor
            this.$emit('reset-color')
        },
    },
})
</script>

<style lang="scss" scoped>
.min-width {
    min-width: 65px;
    width: 65px;
}
</style>

<style lang="scss">
.color-picker-label {
    svg {
        width: 13px;
        height: 13px;
    }
}
</style>
