<template>
    <div class="card-body p-2">
        <div class="card-body border rounded p-2">
            <fieldset class="fieldset">
                <legend>CARD SIZE</legend>
            </fieldset>
            <div class="row w-100 m-0 my-2">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <b-form-radio v-model="cardType" name="card-type" value="standard">
                                <div class="f-14 text-muted" style="height: 1.5rem; line-height: 1.5rem">Standard Grid</div>
                            </b-form-radio>
                        </div>
                    </div>
                    <div v-if="cardType === 'standard'" class="d-flex f-10 align-items-center mb-0 mt-1">
                        <div>
                            <div class="font-weight-bold f-11 text-muted text-right">{{ flashcardItemHeight }}in</div>
                            <b-form-input
                                v-model="flashcardItemHeight"
                                class="flex-glow-1"
                                type="range"
                                min="1"
                                max="8"
                                step="0.1"
                            ></b-form-input>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-0 d-flex flex-column">
                    <div class="d-flex justify-content-end">
                        <div class="d-flex align-items-center">
                            <b-form-radio v-model="cardType" name="card-type" value="3x5">
                                <div class="f-14 text-muted" style="height: 1.5rem; line-height: 1.5rem">
                                    3x5 Index Cards
                                </div>
                            </b-form-radio>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body border rounded mt-2 p-2">
            <fieldset class="fieldset">
                <legend>Print Options</legend>
            </fieldset>
            <div class="row w-100 m-0 my-2">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <b-form-radio v-model="flashcardPrintType" name="print-type" value="single-side">
                                <div class="f-14 text-muted" style="height: 1.5rem; line-height: 1.5rem">
                                    Print Single-Sided
                                </div>
                            </b-form-radio>
                        </div>
                    </div>
                    <p class="f-11 text-muted text-center mt-1">Words and definitions side by side</p>
                </div>
                <div class="col-md-6 p-0 d-flex flex-column">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <b-form-radio v-model="flashcardPrintType" name="print-type" value="double-side">
                                <div class="f-14 text-muted" style="height: 1.5rem; line-height: 1.5rem">
                                    Print Double-Sided
                                </div>
                            </b-form-radio>
                        </div>
                    </div>
                    <p class="f-11 text-muted text-center mt-1">Words and definitions front and back</p>
                </div>
            </div>
        </div>
        <div class="card-body border rounded mt-2 p-2">
            <fieldset class="fieldset">
                <legend>PRACTICE</legend>
            </fieldset>
            <div class="row w-100 m-0 my-2">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <b-form-radio v-model="flipcardType" name="flipcard-type" value="horizontal">
                                <div class="f-14 text-muted" style="height: 1.5rem; line-height: 1.5rem">Turn cards</div>
                            </b-form-radio>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-0 d-flex flex-column">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <b-form-radio v-model="flipcardType" name="flipcard-type" value="vertical">
                                <div class="f-14 text-muted" style="height: 1.5rem; line-height: 1.5rem">Flip cards</div>
                            </b-form-radio>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body border rounded mt-2 p-2">
            <fieldset class="fieldset">
                <legend>Text</legend>
            </fieldset>
            <div class="row w-100 m-0 my-2 align-items-baseline">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                    <div>
                        <FontSelect
                            id="font-body"
                            :key="'title-font' + style.font"
                            class="mt-0 mb-0 pb-0 w-100"
                            field="body_font"
                            size="sm"
                        />
                    </div>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <ColorPicker v-model="style.color" @change="updateFontColor" />
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                        <div class="font-weight-bold text-nowrap">Font Size</div>
                        <div class="text-secondary">
                            {{ parseInt(style.font_size) }}
                        </div>
                    </label>
                    <div class="d-flex f-10 align-items-center mb-0 mt-1">
                        <b-form-input
                            class="flex-glow-1"
                            type="range"
                            min="6"
                            max="64"
                            :value="style.font_size"
                            @input="
                                $store.dispatch('document/setDocumentStyle', {
                                    font_size: Number($event),
                                })
                            "
                            @change="$store.dispatch('document/storeDocumentState')"
                        ></b-form-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import FontSelect from '../../widgets/font-select.vue'
import ColorPicker from '../ColorPicker.vue'

export default defineComponent({
    name: 'PageSetup',
    components: {
        ColorPicker,
        FontSelect,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        ...mapGetters({
            style: 'document/documentStyle',
            flashcard: 'document/flashcard',
        }),
        itemStyle() {
            return this.itemData.style
        },
        flashcardItemHeight: {
            get() {
                return this.itemData.flashcard_item_height
            },
            set(number) {
                this.itemData.flashcard_item_height = number
                this.updateData({ flashcard_item_height: number })
            },
        },
        cardType: {
            get() {
                return this.itemData.page_setup.flashcard_type
            },
            set(type) {
                this.itemData.page_setup.flashcard_type = type
                this.updateData()
                this.$store.dispatch('document/adjustZoomContainer')
            },
        },
        flashcardPrintType: {
            get() {
                return this.itemData.page_setup.flashcard_print_type
            },
            set(value) {
                this.itemData.page_setup.flashcard_print_type = value
                this.updateData()
            },
        },
        flipcardType: {
            get() {
                return this.itemData.page_setup.flipcard_type
            },
            set(value) {
                this.itemData.page_setup.flipcard_type = value
                this.updateData()
            },
        },
    },
    methods: {
        updateData(data = null) {
            if (!data) {
                this.$emit('change', this.itemData)
                return
            }
            this.$emit('change', data)
        },
        async updateFontColor() {
            await this.$store.dispatch('document/setDocumentStyle', {
                color: this.style.color,
            })
            await this.$store.dispatch('document/storeDocumentState')
        },
    },
})
</script>
